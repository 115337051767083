// Grid Related theme changes - Start

::ng-deep .k-grid tr.k-state-selected > td {
    background-color: #FFFFFF
}

.k-grid-toolbar{
    justify-content: end!important;
    margin-right: 1%!important;
    padding-right: 2%!important;
}

.k-toolbar {
background-color: #F2F6FF!important;
}

.k-grid thead {
    background: #e6e6e6;
    color: #0a0909;
    font-weight: bold;
}
 .k-grid-content{
    table{
        tbody{

        .k-alt
{
   background-color:  #EBF3F9;
}}}}


.k-grid .k-table {
 border-collapse: collapse;
}

.k-grid .k-table-row {
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.08);
}

.k-grid tr.childRows {
    background-color: #F2F2F2!important;
  }

  .k-grid tr td.modelClicked {
    background-color: #8E8E8E!important;
  }


.k-table-md{
    font-size: 13px;
    line-height: 14px;
}

.k-table-md col.k-sorted, .k-table-th.k-sorted {
  background-color: transparent;
}

.k-grid .k-grid-filter {
    color: #fff;
}

.k-grid td, .k-grid .k-table-td {
    border-color: #AEAEAE;
    border-width: inherit;
}

.k-grid-header .k-grid-filter {
    width: unset;
}

.k-grid-header .k-grid-filter:hover {
    background: transparent;
}

.k-grouping-header,
.k-grid tr.k-alt {
    background: #fff;
}

.k-grid td {
    border-width: 0 0 1px 0px;
    border-color: rgba(0, 0, 0, 0.15);
}

.k-grid .k-indicator-container:last-child {
    padding: 8px 0px 8px 8px !important;
    border: none;
    margin-right: 9px;
    margin-bottom: -3px;
    background: #eeebeb;
}

.alertCircle {
    width: 18px;
    height: 16px;
}

.k-filtercell .k-input {
    width: 100% !important;
    background-image: url(https://mytest.nsf.org/nsfonline/assets/images/search.png);
    background-repeat: no-repeat;
    background-position: 98.5%;
    // padding-right: 24px;
    border-radius: 0px;
    background-color: #e6e6e6;
}

.k-filtercell .k-input.k-focus {
  background-color: #FFFFFF;
}

.k-grid-md .k-filter-row td, .k-grid-md .k-filter-row .k-table-td, .k-grid-md .k-filter-row .k-table-th {
    padding: 0px 0px 10px 0px;
    padding-inline: 8px;
}

.k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-inline: 12px;
    padding: 8px 0px 0px 10px;
}

.k-pager-nav .k-button {
    background-color: #FFFFFF;
    color: #000000
}

.k-button-flat-primary.k-selected{
    background-color: #005596!important;
    color: #FFFFFF!important;
}

.k-pager{
    background-color: #F2F6FF;
}

.k-grid .k-table-row.k-table-alt-row{
   background-color: #FFFFFF;
}

.k-input {
    border : 0px;
    border-bottom: 1px solid #AEAEAE;
   // border-style: none;
}


.k-pager-numbers .k-link,
.k-pager-wrap .k-link {
    border: 1px solid #333;
}

.k-pager-numbers .k-link.k-state-selected {
    color: #fff;
    background-color: #333;
}

.k-pager-numbers .k-link {
    color: #333;
    padding: 0px !important;
}

.k-pager-info {
    order: unset;
    margin-right: 10px;
}

.k-button-flat {
    border-color: black!important;
    background-color: #FFFFFF!important;
}


.k-filtercell-operator {
    display: none;
}

.k-form-error {
    margin-top: 4px;
    display: flex;
    font-size: 12px;
    font-weight: normal;
}

// Grid Related theme changes - End


//accordion Related theme changes -Start

// .accordion-item {
//     margin-bottom: 30px;
// }

//accordion Related theme changes -end

//label Error

.errorLabel {
    color: red;
    padding-left: 3px;
}

//Scroll bar css - Start

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

/* Track */
//   ::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }

/* Handle */
::-webkit-scrollbar-thumb {
    background: #828282;
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #828282;
}

// scroll bar css - End


// Kemndo-dropdownComponent - start
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel,
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
    color: #015A9E;
    background: #eee;
    border-color: #eee;
}

.k-popup {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 15px #0000001A;
    border: 1px solid #A7AAA9;
    border-radius: 5px;
    opacity: 1;
}

.k-i-arrow-s::before {
    content: "\e015";
}

.k-window {
    border-color: rgba(0, 0, 0, 0.08);
    color: #424242;
    background-color: #EFEFEF;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 6%);
    z-index: 999999 !important;
}

.k-dialog-titlebar {
    border-color: inherit;
    color: white;
    background-color: #015A9E;
}


.k-tabstrip-top>.k-tabstrip-items-wrapper>.k-tabstrip-items {
    width: 100%;
    background: #ffffff;
}

.k-button-solid-primary {
    border-color: #015A9E;
    color: white;
    background-color: #015A9E;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
    border-color: #015A9E;
    background-color: #015A9E;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected>td {
    background-color: none !important;
}

.k-grid-header .k-grid-filter.k-state-active {
    background-color: unset !important;
}

.k-tabstrip-items-wrapper .k-item {
    color: #015A9E;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-state-active {
    border-bottom-color: none;
}

.k-tabstrip-content,
.k-tabstrip>.k-content {
    border-color: #ffffff;
    color: #424242;
    background-color: #ffffff;
}

.k-window-content,
.k-prompt-container {
    padding: 16px 16px;
    border-width: 0;
    border-color: inherit;
    color: inherit;
    background: #ffffff;
    outline: 0;
    overflow: auto;
    position: relative;
    flex: 1 1 auto;
}



.k-tabstrip-items-wrapper .k-item:active,
.k-tabstrip-items-wrapper .k-item.k-state-active,
.k-tabstrip-items-wrapper .k-item.k-state-selected {
    /* border-color: rgba(0, 0, 0, 0.08); */
    /* color: #424242; */
    border-color: #ffffff;
    border-bottom: 2px solid #015a9e !important;
    font-weight: bold;
    color: black !important;
}

// Kendo Dropdown component - end

// kendo dropdown
.k-picker {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

// kendo sidemenu
.k-drawer-item.k-state-selected:hover,
.k-drawer-item.k-state-selected.k-state-hover,

.k-drawer-item.k-state-selected {

    background: #003566;

    color: #fff;
}

.mouse-cursor {
    cursor: pointer;
}

.dynamicMultiSelect .k-icon-wrapper-host .k-i-x{
  margin-right: 5px;
  font-size: 22px;
}

.arrow-button {
  width: 30px;
  height: 38px;
}

// kendo grid padding
.k-grouping-header {
    padding: 12px 0px 5px 0px !important;
    border: 1px dashed #5e5e5e;
    margin-right: 9px;
    background: #eeebeb;
}

.k-grid {
    padding: 2% 1% 2% 1%;
    background-color: #f2f6ff;
}

.k-grid-aria-root {
    padding: 10px 9px 5px 0px !important;
}

//page-title
.title {
    min-height: 24px;
    text-align: left;
    font: normal normal bold 20px/25px "Inter", sans-serif;
    letter-spacing: 0px;
    color: #545764;
    opacity: 1;
    padding: 20px 0px 9px 12px;
}

.back-div {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 19px;
    padding-bottom: 15px;
    cursor: pointer;
}

.padding-div {
    padding: 0px 50px 0px 50px;
}

// grid column checkbox
.k-checkbox {
    border-color: rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border: 0.5px solid #74768A;
    border-radius: 2px;
    opacity: 1;
}

// radio button color
.form-check-input:checked {
    background-color: #015A9E !important;
    border-color: #015A9E !important;
}

.k-input.k-dateinput,
.k-datepicker.k-input[aria-label="Audit Date Filter"] {
    background-image: none !important;
    padding-right: unset !important;
}

// Kendo - dialog
.successPopup {

    .k-window-content,
    .k-prompt-container {
        background: none !important;
    }

    .k-window {
        border-radius: 30px !important;
    }

}

k-state-selected {
    background-color: #015a9e;
}

.k-input-spinner.k-spin-button {
    display: none;
}

// grid selection color
k-grid td.k-state-selected,
.k-grid td.k-selected,
.k-grid tr.k-state-selected>td,
.k-grid tr.k-selected>td {
    background-color: #FFFFFF !important;
}

.k-input-button.k-icon-button.k-button-solid.k-button-solid-base {
    // background-color: #cae6fb;
    border-left: none;
}


.k-grid tr.modelClicked> td.codeColumn {
    background-color: #8E8E8E!important;
  }

  .k-grid tr.modelClicked> td.footnoteColumn {
    background-color: #8E8E8E!important;
  }

//   .modelClicked .codeColumn {
//     background-color: #8E8E8E!important;
//   }

//   .modelClicked .footnoteColumn {
//     background-color: #8E8E8E!important;
//   }


// .fd-cancel-btn, .fd-save-btn, .fd-next-btn{
//     height: 44px;
// }


// Update Styles

.ans-text {
    text-align: left;
    font: normal normal bold 18px/14px "Inter", sans-serif;
    letter-spacing: 0px;
    color: #015A9E;
    opacity: 1;
    margin-bottom: 0;
    margin-top: 5px;
}

.buttonrow {
    margin-bottom: 15px;
}

.arr-btn {
    height: 40px;
    width: 40px;
}

.perfbtn {
    .markasBtn {
        width: 185px;
    }

    button {
        margin: -3.5rem 5px 15px 5px;
        height: 35px;
        width: auto;
    }
}

.fd-cancel-btn,
.fd-save-btn,
.fd-next-btn {
    height: 44px !important;
}

.fd-cancel-btn {
    width: auto;
    background: transparent !important;
    border-color: #015A9E;
    color: #015A9E;
}

.fd-next-btn {
    width: auto;
}

.fd-save-btn {
    width: auto;
}

.comment-main-sec {
    padding: 0 !important;
}

.tabview {
    margin-right: 15px;
}

.k-expander .k-expander-header {
    margin: 0 15px;
}

.k-expanded .k-expander-header {
    margin: 0 15px;
}

@media (max-width: 597px) {
    .tabview {
        margin-right: 0;
        float: none !important;
        text-align: center !important;
    }

    .pre-notes {
        padding-left: 10px;
        padding-right: 10px;
    }

    .add-notes {
        text-align: center;
    }

    .DialogFooter {
        button {
            height: 44px;
            float: none !important;
            padding: 5px 15px;
        }
    }

}



@media (max-width:576px) {

    // .formField-div{
    //     padding: 15px 0px 0px 0px;
    // }
    .back-div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .padding-div {
        padding: 0px 10px 0px 10px !important;
    }

    .title {
        min-height: 24px;
        text-align: left;
        font: normal normal bold 19px/25px "Inter", sans-serif;
        letter-spacing: 0px;
        color: #545764;
        opacity: 1;
        padding: 20px 0px 9px 12px;
    }
}

.k-pager-numbers .k-link.k-state-selected {
    color: #fff;
    background-color: #015A9E;
}

.chart-popup .k-dialog{
    width: 400px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .padding-div {
        padding: 0px 25px 0px 25px !important;
    }
}

.messageDiv {
    .value {
        .textArea {
            .k-input-inner {
                overflow-y: scroll !important;
            }
        }
    }
}

.moreInfoModalClass {
    .modal-dialog {
        min-width: 650px !important;
        padding-top: 50px !important;
    }
}

@media (min-width:200px) and (max-width:576px) {
    .moreInfoModalClass {
        .modal-dialog {
            min-width: 330px !important;
            padding-top: 50px !important;
        }
    }

    .chart-popup .k-dialog{
        width: 380px !important;
    }
}
