/* You can add global styles to this file, and also import other style files */

@import "assets/styles/app.theme.scss";

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/static/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: left;
  font: normal normal normal 12px "Inter", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  background-color: #F7F1ED;
}

.bold {
  font-weight: bold;
  color: #000000 !important;
}

.max-w-screen-xxl {
  max-width: 1400px;
  padding: 0px;
}

.isSearchCategoryHide .adv-button {
  display: block !important;
}

.k-advImage {
  margin-right: 10px;
  margin-top: -5px;
}

.k-radio:checked, .k-radio.k-checked {
  background-image: url('assets/images/radioSelected.svg') !important;
  border: 0px !important;
}

.action-btn .k-icon.k-advImage::before,
.ad-search .k-icon.k-advImage::before {
  background-image: url('assets/images/advIconBlue.svg');
  background-size: 16px 12px;
  display: inline-block;
  width: 16px;
  height: 12px;
  content: "";
}

.action-btn.selected .k-icon.k-advImage::before,
.ad-search.selected .k-icon.k-advImage::before {
  // background-image: url('assets/images/advIconWhite.svg');
}

.k-input-button.k-icon-button.k-button-solid.k-button-solid-base {
  width: 30px;
}

// Grid start
.k-grid .k-hierarchy-cell {
  width: 0;
}

.k-grid .k-header.k-hierarchy-cell {
  border: none;
}

.k-grid .k-hierarchy-cell .k-icon {
  display: none;
}

.k-grid .k-hierarchy-col {
  width: 1px;
}

// Grid end

//Category absolute start
.isSearchCategoryHide .header-block {
  position: relative;

  .detailscontainer {
    position: absolute;
    background: #FFFFFF;
    z-index: 999;
    box-shadow: 5px 5px 7px rgb(96, 96, 96, 0.1);
  }
}

//Category absolute end

// layout start
.isAdvancedSearchClick .right .search {
  // border-bottom: 1px solid #74768A;
}

// layout start

.k-i-caret-alt-down::before {
  content: url(../src/assets/images/normal_path_3618_u13104.svg);
  // content: url(../src/assets/images/Drop-Down-arrow.svg);
}

.k-i-arrow-s::before {
  content: url(../src/assets/images/normal_path_3618_u13104.svg);
  // content: url(../src/assets/images/Drop-Down-arrow.svg);
  font-size: 0px;
}

.k-i-caret-alt-to-left::before {
  content: url(../src/assets/images/Left_DoubleArrow.svg);
  width: 15px;
}

.k-i-caret-alt-left::before {
  content: url(../src/assets/images/Left_SingleArrow.svg);
  width: 15px;
}

.k-i-caret-alt-right::before {
  content: url(../src/assets/images/Right_SingleArrow.svg);
  width: 15px;
}

.k-i-caret-alt-to-right::before {
  content: url(../src/assets/images/Right_DoubleArrow.svg);
  width: 15px;
}

.k-columnmenu-item.k-state-selected {
  color: white !important;
  background-color: #005596 !important;
}

.k-columnmenu-item.k-selected {
  color: white !important;
  background-color: #005596 !important;
}

.k-button-outline-base {
  background-color: #DAEDFC;
  color: #005596 !important;
}

.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link.k-selected,
.k-drawer-item.k-state-selected,
.k-drawer-item.k-selected {
  background-color: #015A9E !important;
  color: #fff !important;

}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #015A9E !important;
  border-color: #015A9E;
}

.btn-outline-primary {
  color: #fff;
  border-color: #015A9E;
  background-color: #015A9E;

}

.k-form-horizontal .k-form-field-wrap {
  position: relative;
  max-width: calc(100% - 0px);
  flex: 1 1 auto;
}

.k-text-selection ::selection {
  background-color: #015A9E;
  color: white;
}

.k-marquee-color {
  color: white;
  background-color: #015A9E;
  border-color: rgba(0, 0, 0, 0.08);
  opacity: .6;
}

.k-text-primary {
  color: #005596;
}

.\!k-text-primary {
  color: #005596 !important;
}

.k-bg-primary {
  background-color: #005596;
}

.\!k-bg-primary {
  background-color: #005596 !important;
}

.k-border-primary {
  border-color: #005596;
}

.\!k-border-primary {
  border-color: #005596 !important;
}

.k-border-t-primary {
  border-top-color: #005596;
}

.\!k-border-t-primary {
  border-top-color: #005596 !important;
}

.k-border-r-primary {
  border-right-color: #005596;
}

.\!k-border-r-primary {
  border-right-color: #005596 !important;
}

.k-border-b-primary {
  border-bottom-color: #005596;
}

.\!k-border-b-primary {
  border-bottom-color: #005596 !important;
}

.k-border-l-primary {
  border-left-color: #005596;
}

.\!k-border-l-primary {
  border-left-color: #005596 !important;
}

.k-border-x-primary {
  border-inline-color: #005596;
}

.\!k-border-x-primary {
  border-inline-color: #005596 !important;
}

.k-border-y-primary {
  border-block-color: #005596;
}

.\!k-border-y-primary {
  border-block-color: #005596 !important;
}

.k-drop-hint-line {
  flex-grow: 1;
  background-color: #005596;
}

.k-drop-hint-h .k-drop-hint-start {
  border-left-width: 6.9282032304px;
  border-left-color: #005596;
  border-right-width: 0;
}

.k-drop-hint-h .k-drop-hint-end {
  border-right-width: 6.9282032304px;
  border-right-color: #005596;
  border-left-width: 0;
}

.k-drop-hint-v .k-drop-hint-start {
  border-top-width: 6.9282032304px;
  border-top-color: #005596;
  border-bottom-width: 0;
}

.k-drop-hint-v .k-drop-hint-end {
  border-bottom-width: 6.9282032304px;
  border-bottom-color: #005596;
  border-top-width: 0;
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  border-color: rgba(0, 0, 0, 0.08);
  color: #005596;
  background-color: #ffffff;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: rgba(45, 116, 218, 1);
  color: white;
  background-color: rgba(45, 116, 218, 1);
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}

.k-checkbox.k-invalid {
  border-color: #f31700;
}

.k-checkbox.k-invalid+.k-checkbox-label {
  color: #f31700;
}

.k-checkbox-wrap .k-ripple-blob {
  color: #005596;
  opacity: 0.25;
}

.k-ripple-container .k-checkbox::after {
  background: #005596;
  opacity: 0.25;
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding-inline: 12px;
  }

.k-picker-md .k-input-inner {
  padding-inline: 8px !important;
}

// ----Multi and select dropdown CSS -----
.k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  color: #545764;
  background-color: rgba(163, 221, 255, 0.2);
}

.k-list-item.k-focus,
.k-selected.k-list-optionlabel.k-focus {
  border: transparent;
  box-shadow: none;
}

.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel:hover,
.k-list-item.k-selected.k-hover,
.k-selected.k-hover.k-list-optionlabel {
  color: #545764;
  background-color: rgba(163, 221, 255, 0.2);
}

.k-list-item {
  &:hover {
    background-color: rgba(163, 221, 255, 1);
  }
}

.k-animation-container {
  .k-list-item {
    span {
      margin-left: 5px;
    }
  }
}

.k-popup {
  .k-popover-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}
// ----Multi select dropdown CSS -----

.k-overlay-primary {
  background-color: #005596;
}

.k-avatar-solid-primary {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-avatar-outline-primary {
  color: #005596;
}

.k-badge-solid-primary {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-badge-outline-primary {
  color: #005596;
}

.k-loader-primary {
  color: #005596;
}

.k-button-solid-base.k-selected {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-button-solid-primary {
  border-color: #214AE4;
  color: white;
  background-color: #214AE4;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
  border-color: #f55f54;
  background-color: #f55f54;
}

.k-button-solid-primary:active,
.k-button-solid-primary.k-active {
  border-color: #e0574d;
  background-color: #e0574d;
}

.k-button-solid-primary.k-selected {
  border-color: #e0574d;
  background-color: #e0574d;
}

.k-button-flat-primary {
  color: #005596;
}

.k-button-link-primary {
  color: #005596;
}

.k-button-clear-primary {
  color: #005596;
}

.k-menu:not(.k-context-menu)>.k-item {
  color: #005596;
}

.k-menu-group .k-item>.k-link:active,
.k-menu-group .k-item>.k-link.k-active,
.k-menu-group .k-item>.k-link.k-selected,
.k-menu.k-context-menu .k-item>.k-link:active,
.k-menu.k-context-menu .k-item>.k-link.k-active,
.k-menu.k-context-menu .k-item>.k-link.k-selected {
  color: white;
  background-color: #005596;
}

.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-color: rgba(0, 0, 0, 0.08);
  color: #005596;
  background-color: #ffffff;
}

.k-overflow-container .k-button.k-selected {
  color: white;
  background: #005596;
}

.k-tooltip-primary {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-tooltip-primary .k-callout {
  color: #005596;
}

.k-listbox .k-drop-hint {
  border-top-color: #005596;
}

.k-progressbar .k-selected {
  border-color: #eb5b51;
  color: white;
  background-color: #005596;
}

.k-progressbar-chunk .k-selected {
  background-color: #005596;
}

.k-circular-progressbar-arc {
  stroke: #005596;
  transition: stroke .5s ease;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-ripple-container .k-radio::after {
  background: #005596;
  opacity: 0.25;
}

.k-slider .k-slider-selection {
  background-color: #005596;
}

.k-slider .k-draghandle {
  border-color: #005596;
  color: white;
  background-color: #005596;
  border-radius: 50%;
}

.k-slider .k-draghandle:hover {
  border-color: #005596;
  background-color: #f55f54;
}

.k-slider .k-draghandle:active,
.k-slider .k-draghandle.k-pressed {
  border-color: #005596;
  background-color: #005596;
}

.k-calendar .k-calendar-view .k-today {
  color: #005596;
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  border-color: rgba(0, 0, 0, 0.08);
  color: white;
  background-color: #005596;
}

.k-calendar .k-range-start .k-calendar-cell-inner,
.k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
  background-color: #005596;
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  color: #005596;
}

.k-time-list .k-item:hover {
  color: #005596;
}

.k-treeview-leaf.k-selected {
  color: white;
  background-color: #005596;
}

.k-treeview .k-treeview-load-more-button {
  color: #005596;
  background-color: transparent;
}

.k-rating-item.k-selected {
  color: #005596;
}

.k-rating-item.k-selected:focus,
.k-rating-item.k-selected.k-focus {
  color: #005596;
}

.k-rating-item:hover,
.k-rating-item.k-hover {
  color: #005596;
  cursor: pointer;
}

.k-switch-on .k-switch-track {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-external-dropzone-hover .k-icon,
.k-external-dropzone-hover .k-dropzone-icon {
  color: #005596;
}

.k-appbar-primary {
  color: white;
  background-color: #005596;
}

.k-fab-solid-primary {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.2);
  outline: 1px solid #005596;
  outline-offset: -1px;
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-window {
  box-shadow: 0px 0px 20px 10px #000 !important;
}
kendo-window-titlebar {
  color: white !important;
  background-color: #005596 !important;
}

.k-dialog-primary .k-dialog-titlebar {
  color: white;
  background-color: #005596;
}

.k-drawer-item.k-selected {
  color: white;
  background-color: #005596;
}

.k-notification-primary {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-bottom-nav-solid.k-bottom-nav-primary {
  color: #ffc8c5;
  background-color: #005596;
}

.k-bottom-nav-flat.k-bottom-nav-primary .k-bottom-nav-item.k-selected {
  color: #005596;
}

.k-breadcrumb-link {
  &.k-disabled{
    color: #4C4C4E;
    font-weight: bold;
    opacity: 1;
    margin-left: 5px;
  }
}

.k-breadcrumb-root-link:focus,
.k-breadcrumb-root-link.k-focus{
  box-shadow: none;
}

.k-breadcrumb-delimiter, .k-breadcrumb-delimiter-icon {
  opacity: 1;
  margin: 0 10px 0 15px;
}

.k-breadcrumb-link:hover,
.k-breadcrumb-link.k-hover {
  color: #005596;
  text-decoration: underline;
  background-color: transparent;
  box-shadow: none;
}

.k-i-chevron-right::before {
  font-weight: bold;
  color: #000000;
  font-size: 18px;
}

.k-breadcrumb-link  {
  color: #005596;
}
.k-breadcrumb-root-link {
  color: #005596;
  padding-left: 0px;

  &:hover {
    text-decoration: underline;
    background: none;
  }
}

.k-stepper .k-step-done .k-step-indicator {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-stepper .k-step-current .k-step-indicator {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-tabstrip-items-wrapper .k-item {
  color: #005596;
}

.k-expander-title {
  color: #005596;
}

.k-panelbar>.k-item>.k-link,
.k-panelbar>.k-panelbar-header>.k-link {
  color: #005596;
  background-color: #ffffff;
}

.k-panelbar>.k-item>.k-link.k-selected,
.k-panelbar>.k-panelbar-header>.k-link.k-selected {
  color: white;
  background-color: #005596;
}

.k-panelbar .k-group>.k-item>.k-link.k-selected,
.k-panelbar .k-group>.k-panelbar-item>.k-link.k-selected,
.k-panelbar .k-panelbar-group>.k-item>.k-link.k-selected,
.k-panelbar .k-panelbar-group>.k-panelbar-item>.k-link.k-selected {
  color: white;
  background-color: #005596;
}

.k-splitbar:focus,
.k-splitbar.k-focus {
  color: white;
  background: #005596;
}

.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
  color: #005596;
}

.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries {
  color: #005596;
}

.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected {
  color: #005596;
  background: none;
}

.k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
  color: #005596;
}

.k-grid-header .k-grid-filter.k-active,
.k-grid-header .k-header-column-menu.k-active,
.k-grid-header .k-grid-header-menu.k-active,
.k-grid-header .k-hierarchy-cell .k-icon.k-active,
.k-grid-header .k-hierarchy-cell .k-svg-icon.k-active {
  color: white;
  background-color: #005596;
}

.k-columnmenu-item.k-selected {
  color: white;
  background-color: #005596;
}

.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-selected {
  color: white;
  background-color: #005596;
}

.k-spreadsheet-selection {
  border-color: #005596;
  background-color: rgba(255, 99, 88, 0.25);
  box-shadow: inset 0 0 0 1px #005596;
}

.k-spreadsheet .k-single-selection::after {
  border-color: #ffffff;
  background-color: #005596;
}

.k-spreadsheet-active-cell {
  box-shadow: inset 0 0 0 1px #005596;
  background-color: #ffffff;
}

.k-spreadsheet-active-cell.k-right {
  box-shadow: inset 0 0 0 1px #005596, inset -1px 0 0 1px #005596;
}

.k-spreadsheet-active-cell.k-bottom {
  box-shadow: inset 0 0 0 1px #005596, inset 0 -1px 0 1px #005596;
}

.k-spreadsheet-active-cell.k-bottom.k-right {
  box-shadow: inset 0 0 0 1px #005596, inset -1px -1px 0 1px #005596;
}

.k-spreadsheet .k-auto-fill {
  border-color: #005596;
  background-color: rgba(255, 99, 88, 0.25);
  box-shadow: inset 0 0 0 1px #005596;
}

.k-spreadsheet .k-resize-handle,
.k-spreadsheet .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-marker {
  background-color: #005596;
}

.k-spreadsheet-has-comment::after {
  border-color: #005596 #005596 transparent transparent;
}

.k-spreadsheet .k-filter-range {
  border-color: #005596;
}

.k-spreadsheet-popup .k-button:active,
.k-spreadsheet-popup .k-button.k-active,
.k-spreadsheet-popup .k-button.k-selected {
  color: white;
  background-color: #005596;
}

.k-spreadsheet-drawing.k-spreadsheet-active-drawing {
  outline-color: #005596;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle {
  border-color: #005596;
  background-color: #005596;
}

.k-rtl .k-spreadsheet .k-spreadsheet-has-comment::after,
.k-spreadsheet[dir="rtl"] .k-spreadsheet-has-comment::after,
[dir="rtl"] .k-spreadsheet .k-spreadsheet-has-comment::after {
  border-color: #005596 transparent transparent #005596;
}

.k-filter .k-filter-preview-field {
  color: #005596;
}

.k-taskboard-card .k-card-title {
  color: #005596;
}

.k-ct-popup .k-selected {
  border-color: rgba(0, 0, 0, 0.08);
  color: white;
  background-color: #005596;
  background-image: none;
}

.k-editor-content .k-text-selected,
.k-editor-content::selection {
  color: white;
  background-color: #005596;
}

.k-gantt .k-treelist-scrollable .k-task-milestone.k-selected .k-task-milestone-content {
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #005596;
}

.k-gantt .k-gantt-dependencies-svg polyline.k-selected {
  stroke: #005596;
}

.k-task-milestone.k-selected {
  background-image: none;
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #005596;
}

.k-task-summary.k-selected .k-task-summary-complete {
  color: #005596;
}

.k-task-single.k-selected .k-task-complete {
  background-color: #005596;
}

.k-gantt-line.k-selected {
  color: #005596;
}

.k-task-planned .k-task-moment {
  border-color: #005596;
  background-color: transparent;
}

.k-task-planned .k-task-duration {
  background-color: #005596;
}

.k-task-planned:hover .k-task-moment {
  background-color: #005596;
}

.k-planned-tooltip {
  background-color: #005596;
}

.k-event.k-selected {
  color: white;
  background-color: #005596;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-scheduler-marquee::before,
.k-scheduler-marquee::after {
  border-color: #005596;
}

.k-scheduler-yearview .k-day-indicator {
  background-color: #005596;
}

.k-chat .k-bubble a {
  color: #005596;
}

.k-chat .k-alt .k-bubble {
  border-color: #005596;
  color: white;
  background-color: #005596;
  box-shadow: 0 1px 2px rgba(255, 99, 88, 0.2);
}

.k-chat .k-quick-reply {
  border-color: #005596;
  color: #005596;
  background-color: transparent;
}

.k-chat .k-quick-reply:hover {
  border-color: #005596;
  color: white;
  background-color: #005596;
}

.k-timeline .k-timeline-flag {
  color: white;
  background-color: #005596;
}

.k-timeline .k-timeline-circle {
  background-color: #005596;
}

.k-timeline-horizontal .k-timeline-flag::after {
  background-color: #005596;
}

.k-scrollview-pageable>.k-button.k-primary,
.k-scrollview-nav>.k-link.k-primary {
  background: #005596;
  border: #005596;
}

.k-var--primary {
  background-color: #005596;
}

.k-var--selected-background {
  background-color: #005596;
}

.k-var--series-a {
  background-color: #005596;
}

.k-var--series-1 {
  background-color: #005596;
}

.k-var--gauge-pointer {
  background-color: #005596;
}

.k-map .k-marker {
  color: #005596;
}

//cursor pointer and default Start
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

/***Detail grid***/
.details-grid {
  .header-section {
    z-index: 9999;
    position: relative;
  }

  app-common-grid {
    position: relative;
    top: -55px;

    .gridMargin {
      margin: 0px;

      kendo-grid.k-grid {
        padding: 0px;
        background-color: #FFFFFF;

        kendo-grid-toolbar.k-toolbar {
          background-color: #FFFFFF !important;
        }

        kendo-pager {
          background-color: #FFFFFF;
        }
      }
    }
  }


}
.k-dialog-wrapper {
  z-index: 99999 !important;
}
.k-pager-numbers {
  display: none;
}
.k-dropdown-list {
  background: #214AE4;
  border: none;
  color: #fff;
  pointer-events: none;
  width: 35px !important;
  appearance: none !important;
  height: 35px !important;
  margin-right: 5px;
  padding: 7px !important;
  text-align: center;
  border-radius: 40px;
}
.k-pager-numbers-wrap {
  margin-left: 10px;
}
.k-input {
  border: 1px solid #E5D5C7 !important;
}

.dynamicMultiSelect .k-icon-wrapper-host .k-i-x {
  color: #214AE4;
}

.k-grid thead {
  background: #F7F1ED;

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    border-bottom: 1px solid #000;
    background: #F7F1ED;
  }
  .k-grid .k-table-th {
    border-color: #F7F1ED;
  }
  .k-input {
    border: 0px !important;
  }
  
}
.k-grid .k-table-row {
  border-bottom: 1px solid #F7F1ED !important;
}
.k-grid .k-grid-header .k-table-th {
  border-color: #F7F1ED !important;
}
.k-master-row .k-table-row {
  border-bottom: 1px solid #E5D5C7 !important;
}

.k-popup {
  .width-100 {
    .form-control {
      margin-bottom: 20px !important;
    }
  }
}

.mobileViewFilterComponent {
  kendo-window {
    width: 100%;
    height: auto;
    margin-top: 10%;
  }

  kendo-window-titlebar {
    display: none !important;
  }

  k-window-content {
    background-color: #E5D5C7 !important;
    box-shadow: none !important;
  }
  

  kendo-filter-group {
    .k-filter-toolbar {
      display: none;
    }

    .k-filter-toolbar-item {
      button {
        display: none !important;
      }
    }
    
    .k-filter-operator {
      display: none !important;
    }

    button {
      display: block;
    }
  }

  kendo-filter-expression {
    .k-filter-toolbar {
      display: block !important;
    }
    .k-filter-operator {
      display: none !important;
    }
    .k-toolbar {
      background-color:  transparent !important;

      div:nth-of-type(4) {
        display: none !important;
      }
    }
  }
}

.k-input-solid:focus-within, .k-input-solid:focus, .k-input-solid.k-focus {
  box-shadow: 0 0 0 0 !important;
}

.k-button-solid-base:focus, .k-button-solid-base.k-focus {
  box-shadow: 0 0 0 0 !important;
}

.isSearchCategoryHide {
  .header-tab {
    display: block !important;
  }
}

.k-window-content iframe {
  width: 100%;
  height: 100%;
}

/****** for smaller devices *****/
@media screen and (max-width: 768px) {
  .k-input-value-text, .k-input-inner {
    font-size: 14px;
    font-weight: 400;
    color: #424242;
  }
  .k-picker-md .k-input-inner {
    font-weight: 700;
    font-size: 16px;
  }
  .k-popover-callout.k-callout-s {
    left: 72%;
  }
  .k-grid thead {
    background: #ECF7F2;
    display: none;
  }

  .k-grid .k-table-row {
    border: 0px !important;
    border-top: 1px solid #F7F1ED !important;
  }

  .k-grid {
    .k-detail-row {
      border-top: 0px !important;
    }
  }

  .k-grid table tr:first-child {
    border-top: 0px !important;
  }
  
  .k-dropdown-list {
    margin: 0 5px !important;
  }

  .k-window-content, .k-prompt-container {
    border-bottom: 1px solid #E5D5C7;
    background: linear-gradient(0deg, rgba(229, 213, 199, 0.20) 0%, rgba(229, 213, 199, 0.20) 100%), #FFF;
  }

  .k-filter {
    background-color: transparent;
    width: 100%;

    .k-filter-item, .k-filter-lines {
      padding-inline-start: 0px;
    }

    // .k-filter-item:last-child{
    //   display: none;
    // }

    .k-toolbar {
      padding: 0;
      margin: 0;
      background: transparent !important;
      border: 0px;
      margin-right: 0px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      div {
        width: 100%;
        margin-top: -10px;
        margin-bottom: 5px;

        .k-picker {
          width: 100%;
          border: none;
          background: transparent;
        }

        kendo-filter-text-editor {
          width: 100%;

          .k-input {
            width: 100%;
            border: none !important;
            background: transparent;
            border-radius: 0;
            border-bottom: 1px solid #14244E !important;
            margin-top: -20px;
          }
        }
      }
    }

    .k-input-md .k-input-inner {
      padding-inline: 8px;
    }
  }

  .k-filter .k-filter-item::before {
    width: 0px;
  }
  .k-filter .k-filter-lines .k-filter-item:last-child > * > .k-filter-toolbar::after {
    width: 0px;
  }
  .k-filter .k-filter-toolbar::before {
    height: 0px;
  }

  .isSearchCategoryHide {
    .header-tab {
      background-color: transparent !important;
    }
  }

  /*** search Detail grid start ***/
  .search-detials {
    .k-table-md {
      width: 90% !important;
    }
    .k-grid {
      .k-toolbar {
        background-color: #ECF7F2 !important;
      }
    }
    .toolbar-block {
      width: 90% !important;
    }
    .pdf-div {
      padding: 0px 0 10px 20px !important;
    }

    .k-filter {
      .k-filter-item:last-child{
        display: none;
      }
    }
  }
  /*** search Detail grid end ***/

  /*** product Detail grid start ***/
  .product-details-block {

    .table-ancher {
      text-decoration: underline !important;
    }

    .product-details {
      padding: 3% !important;
    }

    .k-grid {
      .k-toolbar {
        background-color: #fff !important;
      }
    }

    .details-grid {

      .k-pager-md {
        display: none;
      }
      .toolbar-block {
        width: 100% !important;
      }
      .filterBtn-block {
        width: 100%;
      }

      .sharePagePopup {
        .paddingSection {
          margin-left: -10px;
        }
        .isPopupEnabled {
          margin-left: 0;
        }
      }

      .pdf-div {
        display: block !important;
        margin: 0;
        padding: 0 !important;

        .head-right {
          width: auto;
          display: block;
          float: left;
        }

        .margin-left50 {
          margin: 15px 0 0 0 !important;
          display: block;
        }
      }

      .k-table-md {
        width: 99% !important;
      }

      .header-section {
        z-index: 11;
        position: relative;
      }

      app-common-grid {
        position: relative;
        top: -55px;

        .gridMargin {
          margin: 0px;

          kendo-grid.k-grid {
            padding: 0px;
            background-color: #FFFFFF;

            kendo-grid-toolbar.k-toolbar {
              background-color: #FFFFFF !important;
            }

            kendo-pager {
              background-color: #FFFFFF;
            }
          }
        }
      }

      .selectedFilterContainer {
        width: 100% !important;
      }
    }
  }

  .sub-header-info {
    .disclaimer-block {
      width: 100% !important;
      margin-top: 0 !important;
    }
  }
  /*** Detail grid end ***/
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .product-details-block { 
    width: 98%;
    margin: auto;
  }
}

@media (max-width:576px) {
  .k-popover-callout.k-callout-s {
    left: 77%;
  }
}

@media(max-width:400px) {
  .k-popover-callout.k-callout-s {
    left: 80%;
  }
}

.cdk-virtual-scroll-content-wrapper {
  position: static !important;
}
.k-cell-inner{
  .k-sort-icon,.k-sort-order{
    display: none!important;
  }
}
